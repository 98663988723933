import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';
import ListItemText from '@mui/material/ListItemText';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';
import p1 from 'assests/Photos/Events/Investiture2023/1.jpg';
import p2 from 'assests/Photos/Events/Investiture2023/2.jpg';
import p3 from 'assests/Photos/Events/Investiture2023/3.jpg';
import p4 from 'assests/Photos/Events/Investiture2023/4.jpg';
import p5 from 'assests/Photos/Events/Investiture2023/5.jpg';
import p6 from 'assests/Photos/Events/Investiture2023/6.jpg';
import p7 from 'assests/Photos/Events/Investiture2023/7.jpg';
import p8 from 'assests/Photos/Events/Investiture2023/8.jpg';
import p9 from 'assests/Photos/Events/Investiture2023/9.jpg';
import p10 from 'assests/Photos/Events/Investiture2023/10.jpg';
import pdf1 from 'assests/Pdf/Events/web.pdf';




// import SidebarArticles from 'views/Dasara/components/SidebarArticles/SidebarArticles';
import { Typography } from '@mui/material';
import Container from 'components/Container';
import Events2023 from 'views/Events2023/Events2023';



const Investiture2023 = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const photos = [
    {
      src: p1,
      source: p1,
      rows: 1,
      cols: 1,
    },
    {
      src: p2,
      source:p2,
      rows: 1,
      cols: 1,
    },
    {
      src: p3,
      source: p3,
      rows: 2,
      cols: 1,
    },
    {
      src: p4,
      source: p4,
      rows: 2,
      cols: 1,
    },
    {
        src: p5,
        source: p5,
        rows: 2,
        cols: 1,
      },
      {
        src: p6,
        source: p6,
        rows: 1,
        cols: 1,
      },
      {
        src: p7,
        source: p7,
        rows: 1,
        cols: 1,
      },
      {
        src: p8,
        source: p8,
        rows: 1,
        cols: 1,
      },
      {
        src: p9,
        source: p9,
        rows: 1,
        cols: 1,
      },
      {
        src: p10,
        source: p10,
        rows: 1,
        cols: 1,
      },
    // {
    //   src: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
    //   source: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
    //   rows: 1,
    //   cols: 1,
    // },
  ];

  return (
    <Main>
        <Container>
    <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center'>
      INVESTITURE CEREMONY
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
      Classes: 6-12	 Date: 23 JUNE 2023
        </Typography>

        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        In a momentous event, National Public school Yeshwanthpur held its annual Investiture Ceremony on Friday 23 June 2023, 
        an occasion that marked the formal appointment of student council members and leaders for the new academic year. 
        The ceremony, held with great enthusiasm and pride, showcased the essence of leadership and the potential within 
        every student.
        {/* <br/><br/> */}
        {/* This spectacular annual event engulfed all the exciting and interesting components of celebration for students.
A ceremonial inaugural by the Principals gave a positive start to the festivities that followed. 
The event commenced with the lighting of the lamp by Principal Academics, Ms. Nikitaa Kalro, Principal Administration, Ms. Sheeja Manoj and the guest for the day, eminent resource person, Mrs. Lavanya Prasad. To invoke the blessings of the Almighty, a shloka was chanted by the teachers and students. Teachers and students assembled in the ground to witness the inaugural function. The event was declared open by the Principals and the revelries began. */}


        <br></br>
        The newly elected student council members, adorned with their badges and sashes, stood tall as they pledged to 
        uphold the values of the institution and lead their fellow students. The leaders were addressed by the Principal, 
        Ms Sheeja Manoj with an inspiring speech, who apprised the students to enhance their Leadership quotient by 
        practising the three Intelligent Factors of   3I’s and 3F’s. A leader should have a deep Insight, take Initiative 
        and should possess Intuitiveness for the upcoming duties. The three F’s signify Foresight to anticipate their 
        actions and its consequences, be Forthright in their actions and exhibit a Fervour to promote unity and inclusiveness 
        so as to harness the collective capabilities of everyone. It definitely made a huge impact on all the leaders and the 
        students as they embark on this journey called life. 
        <br></br>
        The newly appointed school Prefect, filled with determination and passion, on behalf of the entire cabinet and 
        council, expressed their gratitude for the trust bestowed upon them. They articulated their visions, promising 
        to work diligently towards the betterment of the school and the student body. 
        <br></br>
        The Investiture Ceremony served as a testament to the commitment of the school in nurturing leadership qualities 
        and character development in the students. It was a reminder of the immense potential within each individual, 
        igniting a spark of inspiration that will continue to burn brightly throughout their academic journey.

        </Typography>
      </Box>
      
      <Typography
          variant={'h5'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
"To be inspired is great, but to inspire is an honour."
<br/>
        </Typography>

        <Button 
                    href={pdf1}>
                    <ListItemText
                      sx={{ margin: 0 }}
                      primary="School Cabinet and Student Council Members 2023-24"
                      secondary="Click here to view"
                      primaryTypographyProps={{
                        variant: 'h6',
                        fontWeight: 700,
                      }}
                      secondaryTypographyProps={{ variant: 'subtitle2' }}
                    />
          </Button>
      <Box>
        <ImageList
          variant="quilted"
          cols={2}
          rowHeight={isMd ? 200 : 150}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>
    {/* <Typography variant={'subtitle1'} align={'justify'}>
      Click below for newly elected student council members
</Typography>
<br></br> */}
    
    <Grid item xs={12} md={4}>
             
             {/* <Box marginBottom={4}>
               <Events2023 />
             </Box> */}
          
           {/* <SidebarNewsletter /> */}
         </Grid>
    </Grid> 
    </Container>
    </Main>
   
  );
};

export default Investiture2023;